import { useEffect, useState } from "react";
import LogoWA from "../assets/wa-logo.png";

import { useTranslation } from 'react-i18next';

export default function WhatsappHelp() {
  const [show, setShow] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if(process.env.REACT_APP_WHATSAPP_NUMBER!=='0') {
      setTimeout(() => setShow(true), process.env.REACT_APP_WHATSAPP_TIMEOUT_MSG);
      setTimeout(() => setShowButton(true), process.env.REACT_APP_WHATSAPP_TIMEOUT_CLOSE_BTN);
    }
  }, []);

  return (
    <div className="whatsapp-help">
      {show ? (
        <div className="wrapper animate__animated animate__fadeInUp">
          <a href={`https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}`} target="_blank" rel="noreferrer">
            <img src={LogoWA} alt="Logo de Whatsapp" />
            <p>{t('whatsappMsg')}</p>
          </a>
          {showButton ? (
            <button className="close animate__animated animate__fadeInDown" onClick={() => setShow(false)}>
              <span>x</span>
            </button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
