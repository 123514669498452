import { useContext, useEffect, useState } from "react";
import IconButton from "../assets/icon-button.png";
import Id from "../components/Id";
import { OCRContext } from "../contexts/OCRProvider";
import incode from "../incode.config";

import Loading from "../containers/Loading";
import Error from "./Error";

import { useTranslation, Trans } from 'react-i18next';

export default function ProcessFinished({ accountId, customer, holder, session, token, deeplink }) {
  const { t } = useTranslation();

  const { ocr } = useContext(OCRContext);
  const [status, setStatus] = useState("");
  const [errorData, setError] = useState(null);
  const [validationResult, setValidationResult] = useState(false);

  const returnToApp = () => {
    setTimeout(function() { var ww = window.open(window.location, '_self'); ww.close(); }, 3000);
    window.location.href = `${deeplink}${status}`;
  };
  
  //const capitalize = str => str.charAt(0) + str.slice(1).toLowerCase();

  useEffect(() => {
    let getScore = async () => await incode.getScore({ token: session.token }).catch(error => console.log(error));
    const postScore = async () => {
      let score = await getScore();
      let dataToSend = {
        customer_account_id: Number(accountId),
        interview_id: session.interviewId,
        holder,
        json_ocr: ocr,
        json_score: score,
      };

      let data = new FormData();
      for (var key in dataToSend) {
        if (key === "json_ocr" || key === "json_score") data.append(key, JSON.stringify(dataToSend[key]));
        else data.append(key, dataToSend[key]);
      }
      /*
      let {
        overall: { value, status },
        reasonMsg,
      } = score;
      */
      //reasonMsg = reasonMsg.replace(/\s/g, "%20");

      const res = await fetch(`${process.env.REACT_APP_API_URL}/customersAccounts/biometric-verification/${accountId}/${token}`, {
        method: "POST",
        body: data,
      });

      const jsonRes = await res.json(); // { data: { success: true, account_status: 'APP' }, error: { code: null} };

      // La idea es validar cuál es el resultado de la verificación biometrica
      // Y si es exitoso, extraer el account_id_status y pasarlo por parámetro
      // en el deep link a la mobile app.
      
      if (jsonRes.error.code) {
        setError({ "status": "error", "message": jsonRes.error.description, "value": jsonRes.error.code, code: jsonRes.error.code });
      }
      else
      if (jsonRes.data.success) {
        const { account_status, msg } = jsonRes.data;
        setStatus(`?value=${account_status}&status=success&reasonMsg=${msg}`);
        setValidationResult(true);
      } else {
        setError({ "status": "fail", "message": jsonRes.data.msg, "msg_id": jsonRes.data.msg_id, "value": jsonRes.data.account_status, code: "100" });
      }
    };

    postScore();
  }, [ocr, session, accountId, holder, token]);

  const { first_name, last_name, joint_first_name, joint_last_name } = customer.account;
  //const name = ocr? capitalize(ocr.name.firstName) : (holder==='first'? first_name : joint_first_name);
  const name = holder==='first'? first_name+' '+last_name : joint_first_name+' '+joint_last_name;
  const accountType = joint_first_name? 'joint' : 'single';
  
  if(errorData) return <Error data={errorData} deeplink={deeplink}/>;

  if(!validationResult) return <Loading />;

  return (
    <div className="finished">

      <div className="results">
        <h1 className="text-center pb-2">{t('viewFinished.title', { first_name: name })}</h1>
        <p className="pb-2">
          <Trans 
            i18nKey={"viewFinished."+accountType+(accountType==='joint'? "."+holder+"." : ".")+"messageApproved"}
            //values={{ identification_type: handleIdentificationType(identification_type), idNumber }}
            components={{ span: <span />, bold: <strong />, br: <br /> }}
          />
        </p>
        { ocr? <Id ocr={ocr} /> : null }
      </div>
      
      <button onClick={returnToApp}>
        <img src={IconButton} alt="icon"/>
        <span>{t('viewFinished.backToApp')}</span>
      </button>
    </div>
  );
}
