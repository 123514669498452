import { useTranslation } from 'react-i18next';

export default function Id({ ocr }) {
  const { t } = useTranslation();

  let {
    name: { lastNameMrz, paternalLastName, firstName, givenNameMrz },
    gender,
    nationality, nationalityMrz,
    birthDate,
    documentNumber,
  } = ocr;

  const getBirthDate = birthDate => {
    if(birthDate) {
      const date = new Date(birthDate);
      const day = date.getDate() + 1;
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return '';
  };

  return (
    <div className="id-data">
      <div className="id-bg">
        <div className="id-header">
          <div className="id-logo"></div>
          <div className="id-info">
            <div className="id-country"></div>
            <div className="id-type"></div>
          </div>
        </div>
        <div className="id-content">
          <div className="id-picture"></div>
          <div className="id-ocr">
            <div className="row">
              <div className="id-label">
                <span className="title">{t('viewId.lastname')}</span>
                <span className="data">{lastNameMrz? lastNameMrz : paternalLastName}</span>
              </div>
              <div className="id-label">
                <span className="title">{t('viewId.firstname')}</span>
                <span className="data">{givenNameMrz? givenNameMrz : firstName}</span>
              </div>
            </div>
            <div className="row">
              <div className="id-label">
                <span className="title">{t('viewId.gender')}</span>
                <span className="data">{gender}</span>
              </div>
              { nationality?
                <div className="id-label">
                  <span className="title">{t('viewId.nationality')}</span>
                  <span className="data">{nationalityMrz? nationalityMrz : nationality}</span>
                </div>
                : null
              }
            </div>
            <div className="id-label">
              <span className="title">{t('viewId.birthdate')}</span>
              <span className="data">{getBirthDate(birthDate)}</span>
            </div>
          </div>
        </div>
        <div className="id-footer ">
          <div className="id-label">
            <span className="title">Documento</span>
            <span className="data">{documentNumber}</span>
          </div>
          <div className="id-barcode "></div>
        </div>
      </div>
    </div>
  );
}
