import WhatsappHelp from "./WhatsappHelp";
import MyPackage from "../../package.json";

export default function Layout({ children }) {
  return (
    <div className="app-layout">
      <header className="header">
        <span className="logo"></span>
      </header>
      
      
      <main className="content">
        {children}
      </main>


      <footer className="footer">
        <p>
          Copyright Ⓒ {(new Date()).getFullYear()} BCP Global
          <br/>
          All Rights Reserved v{MyPackage.version}
        </p>
        <span className="logo"></span>
      </footer>
      <WhatsappHelp />
    </div>
  );
}
