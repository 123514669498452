import IconButton from "../assets/icon-button.png";

import { useTranslation } from 'react-i18next';

export default function Error({ data, deeplink }) {
  const reload = () => window.location.reload();

  const returnToApp = (reasonMsg) => {
    window.location.href = `${deeplink}?value=0&status=FAIL&reasonMsg=${reasonMsg}`;
  };
  
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-2 justify-center items-center h-screen w-screen error text-center">
     
      {data.message ? (
        <div className="my-4">
          <p><span> <h1 className="text-3xl">{data.msg_id? t('viewError.er'+data.msg_id) : data.message}</h1></span></p>
        </div>
      ) : null}

      <p className="text-base">{t('viewError.subTitle')}</p>

      { data.code === 403?
        <button onClick={() => returnToApp(data.msg_id? t('viewError.er'+data.msg_id) : data.message) }>
          <img src={IconButton} alt="icon"/>
          <span>{t('viewError.returnToApp')}</span>
        </button>
        :
        <button onClick={() => reload()}>
          <img src={IconButton} alt="icon"/>
          <span>{t('viewError.retry')}</span>
        </button>
      }

    </div>
  );
}