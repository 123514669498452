import { useEffect, useContext } from "react";
import { OCRContext } from "../contexts/OCRProvider";
import incode from "../incode.config";
import Loading from "../containers/Loading";



export default function OCRData({ session, onSuccess, onError, customer, holder }) {
  const { identification_number, joint_identification_number } = customer.account;

  const idNumber = holder==='first'? 
                    ( identification_number? identification_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "") : false ) 
                  : 
                    ( joint_identification_number? joint_identification_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "") : false );

  console.log('idNumber', idNumber);

  const { setOcr } = useContext(OCRContext);
  useEffect(() => {
    let getScore = async () => await incode.getScore({ token: session.token }).catch(error => console.log(error));

    const postScore = async () => {
      let score = await getScore();

      if(score.idValidation.overall.value<30) {
          return onError({ "status": "fail", "message": 'Invalid Document', "msg_id": "InvalidDocument", "value": 0, code: "110" });
      }

      incode.ocrData({ token: session.token }).then(ocrData => {
        setOcr(ocrData);
        const documentNumber = ocrData.documentNumber.replace(/(?:\d)[\s.]+(?=\d)/g, '');
        console.log('OCR', ocrData.documentNumber+ ' => ' +documentNumber +' == '+ idNumber);
        if(idNumber && documentNumber!==idNumber) {
          return onError({ "status": "fail", "message": 'Incorrect Document', "msg_id": "IncorrectDocument", "value": 0, code: "111" });
        }
        onSuccess(ocrData);
      })
      .catch((er) => {
        onError(er);
      });
    };

    postScore();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSuccess, onError, session, customer, holder]);

  return <Loading />;
}
