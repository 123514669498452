export const getAccountInfo = async (account_id, token) => {
  let res;
  const response = await fetch(`${process.env.REACT_APP_API_URL}/customersAccounts/identification/${account_id}/${token}`);
  const json = await response.json();
  if (json.error.code !== null || !json.data || json.data.length === 0) {
    if (!json.data || json.data.length === 0) return { status: "error", message: "No se encontró la cuenta", code: 403 };
    return { status: "error", message: json.error.description, code: 403 };
  }
  res = {
    status: "success",
    account: json.data
  };
  return res;
};
