import { useEffect, useRef } from "react";
import incode from "../incode.config";

export default function BackId({ session, onSuccess, onError }) {
  const containerRef = useRef();
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }
    incode.renderCamera("back", containerRef.current, {
      onSuccess,
      onError: onError,
      token: session,
      numberOfTries: 5,
      showTutorial: true,
      showPreview: false,
      timeout: 50
    });

    isMounted.current = true;
  }, [onSuccess, onError, session]);

  return <div ref={containerRef} className="back-id"></div>;
}